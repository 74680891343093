<template>
  <div class="container">
       <div class="imgcon">
       <img src="@/assets/images/img/img5.png" alt="" srcset="">
     </div>
    
    <div class="con">
      <div class="let">
        <div class="p1">Submit a ticket <img src="@/assets/images/img/img8.png" alt="" srcset="" class="imgg"></div>
        <div class="p2">Lorem ipsum dolor sit ame consectetur adipiscing elit sed do eiusmod tempor incididu.</div>
      </div>
      <div class="rig">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
  <el-form-item  prop="name">
    <div class="tx">Requester*</div>
    <el-input type="text"  placeholder="Enter Name" v-model="ruleForm.name" autocomplete="off"></el-input>
  </el-form-item>
  <el-form-item prop="subject">
     <div class="tx">Subject*</div>
    <el-input type="text" placeholder="Enter Subject" v-model="ruleForm.subject" autocomplete="off"></el-input>
  </el-form-item>
    <el-form-item  prop="message">
     <div class="tx">Message</div>
  <el-input
  type="textarea"
  autosize
  placeholder="Enter your Message"
  v-model="ruleForm.message">
</el-input>
  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="submitForm('ruleForm')">Submit</el-button>
    <el-button @click="resetForm('ruleForm')">Cancel</el-button>
  </el-form-item>
</el-form>
      </div>
    </div>

  </div>
</template>

<script>
import { common_api } from "../../api/ymdfbaby";
export default {
  name: "detail",
  props: {},
  data() {
      return {

        ruleForm: {
        name: '',
        subject: '',
          message:'',
        },
   rules: {
     name: [
            { required: true, message: 'Please enter a name', trigger: 'blur' },
          ],
       email: [
          { required: true, message: 'Please enter email address', trigger: 'blur' },
        { type: 'email', message: 'Please enter the correct email address', trigger: ['blur', 'change'] }
          ],
          
       subject: [
            { required: true, message: 'Please enter your mobile  subject', trigger: 'blur' },
          ],
             message: [
            { required: true, message: 'Please enter comments', trigger: 'blur' },
          ],
        }
      };
    },
    methods: {
 submitForm(formName) {
                console.log(JSON.parse(JSON.stringify(this.ruleForm)))
        this.$refs[formName].validate((valid) => {
          if (valid) {
              common_api.addFeedback(JSON.parse(JSON.stringify(this.ruleForm))).then((res) => {
        if(res.code==200){
           this.$message({
          message: 'Added successfully',
          type: 'success'
        });
        this.ruleForm={
          name: '',
        subject: '',
          message:'',
        }
        }else{
          console.log(res,7777)
         this.$message.error('Add failed');
        }
      });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.imgg{
  width: 28px;
  height: 26px;
  visibility: hidden;
}
/deep/ .el-button--primary{
  background: #5B53FF;
  color: #fff !important;

}
/deep/ .el-button{
  width: 131px;
  min-height: 56px;
  border-radius: 0;
  font-size: 16px;
  border: 1px solid #5B53FF;
  color: #5B53FF;
}
/deep/ .el-textarea__inner{
  height: 179px !important;
  padding-top: 21px;
  padding-left: 14px;
  border-radius: 0;
    font-size: 20px;
  color: #5B53FF;
  border-color: #000000;
}
/deep/ .el-textarea__inner:focus{
  border: 1px solid #5B53FF;

}
/deep/ .el-input__inner:focus{
  border-bottom: 1px solid #5B53FF;

}
/deep/ .el-input__inner{
  padding-left: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  height: 52px;
  border-bottom: 1px solid #0D1022;
  font-size: 20px;
  color: #5B53FF;
  border-radius: 0;
}
.container{
   margin: 0 auto;
  .imgcon{
    width: 100%;
    margin-top: 80px;
    img{
      width: 100%;
    }
  }
  .con{
    display: flex;
    justify-content: space-between;
    margin-top: 77px;
    padding-bottom: 134px;
    .let{
      width: 40%;
      .p1{
        font-size: 56px;
      }
      .p2{
        font-size: 18px;
        width: 65%;
        margin-top: 34px;
      }
    }
    .rig{
      width: 60%;
    }
  }
}

@media (max-width: 1680px) {
 
}
@media (max-width: 1459px) {

}
@media (max-width: 768px){
  .container{
padding: 0 25px;

  }
}
@media (max-width: 500px) {
  .container .con{
    padding-bottom: 90px;
}
  .imgg{
    visibility: visible;
  }
.container .con{
  margin-top: 40px;
}
  .container .imgcon{
    margin-top: 110px;
  }
  /deep/ .el-form-item__content{
    margin-left: 0 !important;
  }
  .container .con .let .p1{
    font-size: 34px;
}

  .container .con .let .p2{
    font-size: 18px;
    width: 100%;
  }
  .container .con .let,.container .con .rig{
    width: 100%;
  }
.container .con{
  display: block;
}
}
</style>
 