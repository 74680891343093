<template>
  <div class="container">
       <div class="imgcon">
       <img src="@/assets/images/img/MaskGroup.png" alt="">
        <p class="ver">Version: {{versionCode}} </p>
     </div>
    
   <div class="bigtextCon">
     <div class="p1con">
      Bitcooo Android
       <img src="@/assets/images/img/img8.png" alt="" srcset="">
     </div>
     <div class="p2con">
      Download our app from the official website. Please make sure that the wallet mnemonics have been properly backed up. 
     </div>
      <el-button type="primary" @click="downAn()">Official Download 
    </el-button>
          <el-button type="primary" @click="Google()">Google Download 
    </el-button>
   </div>
     
     <div class="qrcon">
         <div class="tit">Use your cell phone to install by scaning the qr code </div>
         <div class="qrimg">
           <div class="qrcode" ref="qrCodeUrl"></div>
              </div>

     </div>
  

  </div>
</template>

<script>
import { common_api } from "../../api/ymdfbaby";
import QRCode from 'qrcodejs2'
export default {
  name: "detail",
  props: {},
  data() {
      return {
        qrcodeUrl:'',
		versionCode:'2.0.0',
        form:{
  
        },
   
        rules: {
   
        }
      };
    },
    mounted(){

       this.creatQrCode();
      
    },
    methods: {
       creatQrCode() {
         	let param = {
					'appId': 'android'
				}
          common_api.down(param).then((res) => {
        if(res.code==200){
            this.qrcodeUrl= res.data.appUrl;
             var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text:  this.qrcodeUrl, // 需要转换为二维码的内容
            width: 100,
            height: 100,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
            
			this.versionCode=res.data.versionCode;
        }else{
      
        }
      });
 
        console.log(this.qrcodeUrl,88888888888)
    },
    downAn(){
	let param = {
					'appId': 'android'
				}
          common_api.down(param).then((res) => {
            console.log(res,787878)
        if(res.code==200){
            window.location.href = 'https://files.bitcooo.com/download/BitCooo_'+res.data.versionCode+'_google.com.apk';
			
        }else{
      
        }
      });
    },
       Google(){
         console.log(123123)
            window.location.href = 'https://play.google.com/store/apps/details?id=com.bitleynet.wallet';
    },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
 <style>

.qrcode img{
        width: 210px;
margin: 0 auto;

        height: 210px;
    
}
@media (max-width: 500px) {
  .qrcode img {
    width: 150px ;
    height: 150px ;
}
}
 </style>
<style lang="scss" scoped>

.ver{
    position:absolute;
    right: 60px;
    top: 60px;
    color: #fff;
    font-size: 16px;
}
.qrcon {
    margin-top: 56px;
    padding-bottom: 150px;
}
.qrcon .tit{
    text-align: center;
   font-size: 24px;
   color: #98A2B3;
}
.qrimg{
    margin-top: 36px;
}
.qrcon img{
display: block;
margin: 0 auto;
width: 316px;
height: 316px;
}
/deep/ .el-button--primary{
  width: 182px !important;
  background: #5B53FF;
  position: relative;
  color: #fff !important;
}

/deep/ .el-button{
  width: 182px;
  min-height: 56px;
  border-radius: 0;
  font-size: 16px;
  border: 1px solid #5B53FF;
  color: #5B53FF;
  margin-top: 50px;
}
.botex{
  font-size: 32px;
  width: 80%;
  margin-top: 180px;
}
.bigtextCon{
  margin-top: 65px;
  padding-bottom: 24px;
  border-bottom: 1px solid #F2F4F7;
  .p1con{
    font-size: 56px;
    img{
      width: 46px;
      height: 42px;
      margin-left: 6px;
    }
  }
  .p2con{
  margin-top: 30px;
  font-size: 32px;
  }
}
.container{
   margin: 0 auto;
  .imgcon{
    width: 100%;
    margin-top: 80px;
    height: 445px;
    background: #101323;
    position: relative;
    border-radius: 20px;
    img{
       position: absolute;
       bottom: 0;
      width: 100%;
      height: 100%;
    }
  }

}

@media (max-width: 1680px) {
 
}
@media (max-width: 1459px) {
.qrcon img {
    width: 280px;
    height: 280px;
}
}
@media (max-width: 500px) {
    .qrcon {
    padding-bottom: 70px;
}

    .qrcon img{
    width: 200px;
    height: 200px;
}

    .bigtextCon {
    margin-top: 35px;
    }
    .bigtextCon .p2con {
    margin-top: 20px;
    font-size: 24px;
}

    .bigtextCon .p1con img {
    width: 34px;
    height: 30px;
    }
    .bigtextCon .p1con {
    font-size: 34px;
}

    .ver {
    right: 10px;
    top: 20px;
    }
    .container{
        padding: 0px 20px;
    }
.container .imgcon{
    height: 140px;
}
}
</style>
