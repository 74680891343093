import Vue from 'vue'
import axios from 'axios'

let baseURL = 'https://api.bitcooo.com/'
const service = axios.create({
	withCredentials: false, //表示跨域请求时是否需要使用凭证
	crossDomain: true,
	baseURL,
	timeout: 160000
})

// request请求拦截器,在请求之前做一些处理
service.interceptors.request.use(
	config => {		
		console.log(config,444444444444444444444)
		return config;
	},
	error => {
		console.log(error); // for debug
		return Promise.reject(error);
	}
);

// 配置成功后的响应拦截器
service.interceptors.response.use(res => {
	
	if (res.status == 200) {
		return res.data
	} else {
		return Promise.reject(res.msg);
	}
}, error => {
	// if (error.response.status) {
	// 	switch (error.response.status) {
	// 		case 401:
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// }
	return Promise.reject(error)
})

export default service
