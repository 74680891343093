<template>
 
    <div class="header app" :class="isFixed?'bg':''">
       <div class="container">
      <div class="header_left" @click="handleActive(0)">
        <img src="@/assets/images/img/img1.png" class="img" />
        <img src="@/assets/images/img/img2.png" class="img2" />

      </div>
      <div class="header_center">
        <div
          :class="headerActice == 0 ? 'active' : ''"
          @click="handleActive(0)"
        >
         Home
        </div>
        <div
          :class="headerActice == 1 ? 'active' : ''"
          @click="handleActive(1)"
        >
         About
        </div>
        <div
          :class="headerActice == 2 ? 'active' : ''"
          @click="handleActive(2)"
        >
         Support
        </div>
        <div
          :class="headerActice == 3 ? 'active' : ''"
          @click="handleActive(3)"
        >
         Contacts
        </div>
      </div>
    
    </div>
  </div>

</template>



<script>
export default {
  name: "app-header",
  props: {
    headerActice: {
      type: Number,
      defluat() {
        return 0;
      },
    },
  },
  data() {
    return {
      isShow: false,
      isFixed:false,
    };
  },
    mounted(){
   　window.addEventListener('scroll',this.handleScroll) 
  },
  methods: {
         handleScroll(){
　　let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
// 　　let offsetTop = document.querySelector('#boxFixed').offsetTop; // 要滚动到顶部吸附的元素的偏移量
　　this.isFixed = scrollTop >0 ? true : false; // 如果滚动到顶部了，this.isFixed就为true

    console.log(　this.isFixed,787878)
},
    handleActive(e) {
      console.log(e);
      console.log(this.headerActice,99999999999999999)
      this.$emit("handleActive", e);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 .bg{
   background: #fff !important;
   transition: .8s all;
 }

  .header {
    width: 100%;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    z-index: 1000;
    .container {
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
    .container2{
  //       position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 999;
  display: flex;
    justify-content: space-between;
    align-items: center;
     padding: 10px 0;
    }
    .header_left {
      display: flex;
      align-items: center;
      .img {
        width: 37px;
        height: 37px;
        margin-right: 13px;
      }
      .img2{
        width: 132px;
        height: 24px;
      }
    }
    .header_center {
      display: flex;
      align-content: center;
      div {
        font-family:'';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        color: #1c1c1c;
        opacity: 1;
        padding: 28px 0;
        position: relative;
        cursor: pointer;
      }
      div:not(:last-child) {
        margin-right: 40px;
      }
      .active {
        color: #000;
      }
     
    }
    .header_right {
      position: relative;
      cursor: pointer;
      z-index: 999;
      > img {
        width: 22px;
        height: 18px;
      }
      a {
        img {
          width: 22px;
          height: 18px;
          margin-left: 30px;
        }
      }
      .qrCode {
        position: absolute;
        top: 50px;
        left: -50px;
        z-index: 999;
        img {
          width: 140px;
          height: 140px;
        }
      }
    }
  }





}




@media(max-width:1459px){

}
@media(max-width:1259px){

}
@media(max-width:991px){

}
@media(max-width:500px){

}

</style>
