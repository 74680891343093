<template>
  <div class="footer">
 <div class="container">
  <div class="top">
    <div class="let flex no">
        <div class="tx1"     @click="handleActive(0)">
           Home
          </div>
           <div class="tx1"   @click="handleActive(1)">
          About
          </div>
           <div class="tx1"  @click="handleActive(2)">
          Support
          </div>
    </div>

    <div class="rig">
     service@bitcooo.com
    </div>
         <div class="let sc block"   @click="handleActive(0)">
        <img src="@/assets/images/img/img1.png" class="img" />
        <img src="@/assets/images/img/img4.png" class="img2" />
    </div>
  </div>
  <div class="bottom">
    <div class="let no" @click="handleActive(0)">

        <img src="@/assets/images/img/img1.png" class="img" />
        <img src="@/assets/images/img/img4.png" class="img2" />
    </div>
    <div class="rig">
      <span class="blockk"> METAFORCE CRYPTO TECHNOLOGY LIMITED </span>
       ©2021 Bitcooo All rights reserved.
    </div>
  </div>
 </div>




  </div>
</template>

<script>
export default {
  name: "app-footer",
  props: {
    headerActice: {
      type: Number,
      defluat() {
        return 0;
      },
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    handleActive(e) {
      this.$emit("handleActive", e);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.blockk{
  text-align: right;
  display: block;
}
.sc{
  display: none !important;
}
.top{
 .let{
        display: flex;
        align-items: center;
        .img{

        }
        .img2{

          margin-left: 6px;
        }
      }
}
.flex{
  display: flex;
}
.footer{
  background: #101323;
  .container{
    margin: 0 auto;
    .top{
      display: flex;
      justify-content: space-between;
      padding-top: 50px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba($color: #fff, $alpha: .3);
      .let{
        color: rgba($color: #fff, $alpha: .7);
        font-size: 16px;
        .tx1{
          margin-left: 68px;
        }
              .tx1:first-child{
            margin-left: 0;
          }
      }
      .rig{
        color: #fff;
        font-size: 16px;

      }
    }
    .bottom{
      padding-top: 24px;
      padding-bottom: 68px;
      display: flex;
      justify-content: space-between;
      .let{
        display: flex;
        align-items: center;
        .img{
          width: 30px;
          height: 30px;
        }
        .img2{
          width: 106px;
          height: 20px;
          margin-left: 6px;
        }
      }
      .rig{
        color: rgba($color: #fff, $alpha: .7);
    font-size: 16px;
      }
    }
  }
}

@media (max-width: 991px) {

}
@media (max-width: 500px) {
  .top .let .img{
    width: 25px;
    height: 25px;
}
  .top .let .img2{
    width: 88px;
    height: 16px;
  }
  .footer .container .top {
    display: flex;
    justify-content: space-between;
    padding: 0 14px;
    flex-direction: row-reverse;
    padding-top: 50px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    align-items: center;
}
.footer .container .bottom {
    padding-top: 24px;
    padding-bottom: 32px;
}
 .no{
   display: none !important;
 }

.block{
  display: block !important;
}
   .footer .container .bottom{
     justify-content: center;
   }
}
</style>
