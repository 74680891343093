import axios from './axios'
import qs from 'qs'

/**
 *
 * @returns
 * 通用数据 - 接口
 * start
 *
 */
export const common_api = { 
    getHosAdList: (query, type) => {
        return axios({
            url: "/hosApi/home/getHosAdList/websit_client/" + type + "?",
            method: 'get',
            params: query
        })
    },
       // 新增联系我们
       addContact(data) {
        return axios({
            url: "/api/website/addContact?"+qs.stringify(data),
            method: 'post',
        })
      },
          // 新增反馈信息
          addFeedback(data) {
            return axios({
                url: "/api/website/addFeedback?"+qs.stringify(data),
                method: 'post',
            })
          },
          down(params) {
            return axios({
                url: "/api/home/versionUpgrade?"+qs.stringify(params),
                method: 'get',
            })
          }
}