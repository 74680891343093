<template>
  <div class="container">
     <div class="tit no"> 
          Cryptocurrency wallet built on trust and innovation. 
     </div>
      <div class="tit2 block"> 
        Crypto-<br>currency 
wallet built on trust and innovation. 
     </div>
     <div class="imgcon">
       <img src="@/assets/images/img/img3.png" alt="" srcset="">
     </div>
     <div class="textcon">
      <div class="let">
         👋 BitCooo team consists of blockchain technology experts and IT service experts
      </div>
      <div class="rig">
         <p class="p1">
We take asset security as our first priority, insist on controlling assets independently, value user privacy, do not touch or control any user data, and let data sovereignty return.
         </p>
           <p class="p1 p2">
At the same time, we are also working to create digital asset products that are easy to use, good to use, and secure, making BitCooo products your first choice to understand blockchain and digital assets.
         </p>
      </div>
     </div>
  </div>
</template>

<script>
// import Swiper from '/src/assets/js/swiper.min.js';
import Swiper from '../../node_modules/swiper';
import { common_api } from "../../api/ymdfbaby";

export default {
  name: "Home",
  mounted() {

  

 
  },
  data() {
    return {
   
    };
  },
  created() {
 
  },
  methods: {
 
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.container{
  .tit{
    font-size: 68px;
    margin: 0 auto;
    margin-top: 88px;
    font-weight: bold;
    text-align: center;
    width: 58%;
      background-image:-webkit-linear-gradient(bottom,#9290FE,#ABBDFD,#C78AFF);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
  }
  .imgcon{
    width: 100%;
    margin-top: 80px;
    img{
      width: 100%;
    }
  }
  .textcon{
    margin-top: 130px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 134px;
    .let{
      font-size: 48px;
      width: 32%;
    }
    .rig{
      width: 38%;
      .p1{
        font-size: 20px;
        line-height: 28px;
      }
      .p2{
        margin-top: 32px;
      }
    }
  }
}
.container .tit2 {
    font-size: 52px;
     margin: 0 auto;
    margin-top: 88px;
    font-weight: bold;
    text-align: center;
    width: 100%;
      background-image:-webkit-linear-gradient(bottom,#9290FE,#ABBDFD,#C78AFF);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    line-height: 52px;
    display: none;
}
@media (max-width: 1459px) {
.container .tit{
    width: 90%;
}
.container .textcon .let {
    width: 50%;
}
}
@media (max-width: 1259px) {

}
@media (max-width: 991px) {

}
@media (max-width: 500px) {
  .container .textcon .rig {
    margin-top: 48px;
    width: 100%;
}
  .container .textcon .let{
    font-size: 30px;
    width: 100%;
}
  .container .textcon {
    display: block;
    margin-top: 48px;
  }
.container{
  padding: 0 24px;
}
.no{
  display: none;
}
.block{
  display: block !important;
}
}
</style>