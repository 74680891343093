<template>
  <div>
    <div class="banner">
      <img src="@/assets/images/img/baner_back.png" alt="" class="baner_back" />
      <img src="@/assets/images/img/Vector933.png" alt="" class="baner_back2" />

      <div class="container">
        <div class="let">
          <div class="p1">Make your crypto transaction easier</div>
          <div class="p2">
            Bitcooo is an easy and secure digital wallet<br />
            trusted by millions
          </div>
          <div class="btncon">
            <div class="div1"  @click="downios()">
              <img src="@/assets/images/img/img23.png" alt="" />
              Download for IOS
            </div>
            <div class="div1 div2" @click="downAn()">
              <img src="@/assets/images/img/img22.png" alt="" />
              Download for Android
            </div>
          </div>
        </div>
        <div class="rig">
          <img src="@/assets/images/img/img25.png" alt="" class="imgg1" />
          <img src="@/assets/images/img/Phone1and23.png" alt="" class="imgg2" />
        </div>
      </div>
    </div>

    <div class="bot1">
      <div class="container">
        <p class="p1">Powerful Features</p>
        <div class="divcon">
          <div class="let">
            <div class="top">
              <div class="let">
                <p class="p1">Private & Secure</p>
                <p class="p2">
                  Secure, manage, and exchange your cryptocurrency with the
                  mobile security of face or fingerprint scanning.
                </p>
              </div>
              <div class="rig">
                <img src="@/assets/images/img/img9.png" alt="" />
              </div>
            </div>
            <div class="bottom">
              <div class="div">
                <img src="@/assets/images/img/img10.png" alt="" />
                <p class="tex">24/7 human support</p>
              </div>
              <div class="div">
                <img src="@/assets/images/img/img99.png" alt="" />
                <p class="tex">Friendly and Trusted</p>
              </div>
            </div>
          </div>
          <div class="rig">
            <div class="top">
              <p class="p1">Send & Receive</p>
              <p class="p2">
                Send and receive safely, using a QR code or address, Bitcooo
                automatically checks all addresses for errors.
              </p>
            </div>
            <div class="bottom">
              <img src="@/assets/images/img/img11.png" alt="" class="imgg" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bot2">
      <div class="container">
        <div class="let">
          <img src="@/assets/images/img/img12.png" alt="" />
        </div>
        <div class="rig">
          <p class="tt1">
            More Control When<br />
            You're Ready
          </p>
          <p class="t2">
            Trade with fastest and most secure<br />
            crypto wallet
          </p>
          <div class="list">
            <p class="t1">
              <img src="@/assets/images/img/img14.png" alt="" class="img" />
              <span> Easily earn interest on the crypto in your wallet</span>
            </p>
            <p class="t1">
              <img src="@/assets/images/img/img14.png" alt="" class="img" />
              <span> See your collectibles. Art & NFTs in one place</span>
            </p>
            <p class="t1">
              <img src="@/assets/images/img/img14.png" alt="" class="img" />
              <span> Keep your crypto safe from hackers & scammers</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="bot3 app">
      <div class="container">
        <p class="tit">
          Some powerful<br />
          heading
        </p>
        <div class="bottom">
          <div class="let">
            <div class="div">
              <img src="@/assets/images/img/img17.png" alt="" />
              <div class="divcon">
                <p class="p1">Easy to use multi-chain wallet</p>
                <p class="p2">
                  Easy to manage multiple wallets, your close assistant, trading
                  up or down at a glance
                </p>
              </div>
            </div>
            <div class="div">
              <img src="@/assets/images/img/img16.png" alt="" />
              <div class="divcon">
                <p class="p1">NFT Collection</p>
                <p class="p2">
                     Anytime, Anywhere
Cross Chains, Convenient and Decentralized, All in Here.
                </p>
              </div>
            </div>
            <div class="div">
              <img src="@/assets/images/img/img15.png" alt="" />
              <div class="divcon">
                <p class="p1">Open Applications</p>
                <p class="p2">
               Open-Sources and Safety
The private key is stored in your device and protected with many layers of encryption.
                </p>
              </div>
            </div>
          </div>
          <div class="rig">
            <img src="@/assets/images/img/img18.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="bot3 pc botcc">
      <p class="tit">Some powerful heading</p>

      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="item">
              <div class="top">
                <img src="@/assets/images/img/img17.png" alt="" srcset="" />
                <div class="t1">Easy to use multi-chain wallet</div>
              </div>
              <div class="bottom">
                Easy to manage multiple wallets, your close assistant, trading
                up or down at a glance
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="item">
              <div class="top">
                <img src="@/assets/images/img/img16.png" alt="" srcset="" />
                <div class="t1">
                  NFT<br />
                  Collection
                </div>
              </div>
              <div class="bottom">
               Anytime, Anywhere
Cross Chains, Convenient and Decentralized, All in Here.
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="item">
              <div class="top">
                <img src="@/assets/images/img/img15.png" alt="" srcset="" />
                <span class="t1"
                  >Open<br />
                  Applications</span
                >
              </div>
              <div class="bottom">
              Open-Sources and Safety
The private key is stored in your device and protected with many layers of encryption.
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>

      <div class="botimg">
        <img src="@/assets/images/img/img18.png" alt="" />
      </div>
    </div>

    <div class="bot4">
      <div class="container">
        <div class="let">
          <div class="iti">DOWNLOAD OUR APP</div>
          <div class="iti2">Securely buy, sell, and manage cryptocurrency.</div>
          <div class="btncon">
            <div class="div1" @click="downios()">
              <img src="@/assets/images/img/img23.png" alt="" />
              Download for IOS
            </div>
            <div class="div1 div2"  @click="downAn()">
              <img src="@/assets/images/img/img22.png" alt="" />
              Download for Android
            </div>
          </div>
        </div>
        <div class="rig">
          <img src="@/assets/images/img/img21.png" alt="" srcset="" />
        </div>
      </div>
    </div>
  </div>
</template>




<script>
import Swiper from "../../node_modules/swiper";
import { common_api } from "../../api/ymdfbaby";

export default {
  name: "Home",
  data() {
    return {
    };
  },
  mounted() {
       localStorage.setItem('zheng',false);

      // console.log(  this.header_show,4444)
      // console.log(  localStorage.getItem('zheng'),78787)

    var a = new Swiper(".bot3 .swiper-container", {
      observer: true,
      observeParents: true,
      // slidesPerView: 3,
      centeredSlides: true,
      // spaceBetween: 10,
      speed: 2000,
      autoplay: {
        delay: 4000,
      },
      direction: "horizontal",
      loop: true,
      slidesPerView: "auto",
      centeredSlides: true,
      spaceBetween: 10,

      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });

  },
  methods: {
    downios(){
	let param = {
					'appId': 'ios'
				}
          common_api.down(param).then((res) => {
            console.log(res,787878)
        if(res.code==200){
            window.location.href = res.data.appUrl;
        }else{
      
        }
      });

    },
  downAn(){
    this.$emit("handleActive", 4);
	// let param = {
	// 				'appId': 'android'
	// 			}
  //         common_api.down(param).then((res) => {
  //           console.log(res,787878)
  //       if(res.code==200){
  //           window.location.href = res.data.appUrl;
  //       }else{
      
  //       }
  //     });
    },

  },
};
</script>

<style scoped>
@import "../../node_modules/swiper/css/swiper.css";

.botimg {
  width: auto;
  display: table;
  margin: 0 auto;
  margin-top: 56px;
}

.swiper-wrapper {
  width: 100%;
  padding: 50px 0;
}
.swiper-slide {
  width: 80% !important;
}

.bot3 .item .top {
  display: flex;
}
.bot3 .item .top .t1 {
  font-size: 21px;
}
.bot3 .item .top img {
  width: 68px;
  height: 68px;
}
.bot3 .item .bottom {
  margin-top: 14px;
  font-size: 16px;
}
.bot3 .item {
  background: #fff;
  border-radius: 10px;
  padding: 0 16px;
  padding-top: 24px;
  height: 150px;
  padding-bottom: 43px;
  box-shadow: 0px 0px 10px 0px rgb(90 91 95 / 30%);
}

.bot3 .bottom .let .div {
  transition: 0.5s all;
}
.bot3 .bottom .let .div:hover {
  box-shadow: 0px 0px 10px 0px rgb(90 91 95 / 30%);
  transform: translate3d(0, -3px, 0);
}
</style>
<style lang="scss" scoped>
.bot1 .divcon .rig .bottom .imgg {
  width: 100%;
}
.bot1 .divcon .rig .bottom {
  width: 70%;
  margin: 0 auto;
  margin-top: 138px;
}
.bot1 .divcon .top .rig {
  width: 50%;
}
.bot1 .divcon .top .rig img {
  width: 100%;
}
.banner {
  background: #101323;
  height: 810px;
  width: 100%;
  position: relative;
  .baner_back {
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 8;
    .rig {
      width: 50%;
      margin-top: 150px;
      img {
        width: 100%;
      }
    }
    .let {
      width: 50%;
      .p1 {
        font-size: 78px;
        font-weight: bold;
        background-image: -webkit-linear-gradient(
          bottom,
          #9290fe,
          #abbdfd,
          #c78aff
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .p2 {
        margin-top: 24px;
        color: #d0d5dd;
        font-size: 26px;
      }
      .btncon {
        margin-top: 60px;
        display: flex;
        .div1 {
          width: 200px;
          line-height: 51px;
          display: flex;
          align-items: center;
          border: 1px solid #fff;
          color: #fff;
          font-size: 14px;
          border-radius: 10px;
          justify-content: center;
          img {
            margin-right: 8px;
          }
        }
        .div2 {
          margin-left: 50px;
        }
      }
    }
  }
}
.container {
}

.bot4 {
  padding-top: 100px;
  padding-bottom: 135px;
  .container {
    background: url("../assets/images/img/img20.png") no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 20px;
    padding-top: 96px;
    display: flex;
    justify-content: space-between;
    .let {
      width: 42%;
      margin-left: 111px;
      .iti {
        color: #fff;
        font-size: 20px;
      }
      .iti2 {
        color: #fff;
        font-size: 48px;
        margin-top: 30px;
        font-weight: bold;
      }
      .btncon {
        margin-top: 60px;
        display: flex;
        .div1 {
          width: 200px;
          line-height: 51px;
          display: flex;
          align-items: center;
          border: 1px solid #fff;
          color: #fff;
          font-size: 14px;
          border-radius: 10px;
          justify-content: center;
          img {
            margin-right: 8px;
          }
        }
        .div2 {
          margin-left: 50px;
        }
      }
    }
    .rig {
      width: 37%;
      img {
        width: 100%;
        vertical-align: middle;
      }
    }
  }
}
.bot3 {
  background: #f8f9fc;
  padding: 103px 0;
  .bottom {
    margin-top: 98px;
    display: flex;
    padding: 0 250px;
    .let {
      width: 53%;
      .div {
        display: flex;
        background: #fcfcfd;
        border-radius: 20px;
        padding-left: 34px;
        padding-top: 40px;
        padding-bottom: 59px;
        padding-right: 53px;
        margin-bottom: 32px;
        img {
          width: 96px;
          height: 96px;
        }
        .divcon {
          .p1 {
            font-size: 24px;
          }
          .p2 {
            font-size: 18px;
            margin-top: 11px;
          }
        }
      }
    }
    .rig {
      margin-left: 120px;
    }
  }
  .tit {
    text-align: center;
    font-size: 48px;
  }
}
.bot2 {
  margin-top: 130px;
}
.bot2 .container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 98px;
  .let {
  }
  .rig {
    margin-left: 100px;
    .tt1 {
      font-size: 48px;
      font-weight: bold;
      background-image: -webkit-linear-gradient(
        bottom,
        #9290fe,
        #abbdfd,
        #c78aff
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .t2 {
      margin-top: 32px;
      font-size: 24px;
    }
    .list {
      margin-top: 40px;
      .t1 {
        font-size: 20px;
        margin-bottom: 22px;
        img {
          margin-right: 20px;
          vertical-align: middle;
        }
      }
    }
  }
}
.bot1 {
  margin-top: 97px;
  .p1 {
    font-size: 48px;
  }
  .divcon {
    display: flex;
    justify-content: space-between;
    margin-top: 83px;
    .rig {
      width: 42%;
      border-radius: 10px;
      background: #f8f9fc;
      padding-top: 71px;
      padding-left: 44px;
      .top {
        .p1 {
          font-size: 30px;
        }
        .p2 {
          width: 60%;
          line-height: 28px;
          margin-top: 20px;
          font-size: 18px;
        }
      }
      .bottom {
        position: relative;
        margin-top: 138px;
        .imgg {
          position: relative;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .let {
      width: 54%;
      .top {
        background: #f8f9fc;
        display: flex;
        padding: 82px 10px 0 41px;
        border-radius: 10px;
        .let {
          .p1 {
            font-size: 30px;
          }
          .p2 {
            font-size: 18px;
            color: #0d1022;
            margin-top: 23px;
          }
        }
      }
      .bottom {
        display: flex;
        margin-top: 24px;
        .div {
          width: 48%;
          background: #f8f9fc;
          border-radius: 10px;
          padding-top: 72px;
          padding-bottom: 54px;
          img {
            width: 113px;
            height: 111px;
            margin: 0 auto;
            display: block;
          }
          .tex {
            text-align: center;
            margin-top: 36px;
            font-size: 24px;
          }
        }
      }
      .bottom .div:last-child {
        margin-left: 5%;
      }
    }
  }
}
.baner_back2 {
  display: none;
}
.imgg2 {
  display: none;
  // position: absolute;
  // bottom: -160px;
  // left: 0;
}
@media (max-width: 1680px) {
}
@media (max-width: 1459px) {
  .bot4 .container .let .iti2 {
    color: #fff;
    font-size: 36px;
  }
  .bot3 .bottom .let .div {
    padding-left: 10px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 30px;
    margin-bottom: 28px;
  }
  .bot3 .bottom .rig {
    width: 50%;
  }
  .bot3 .bottom .rig img {
    width: auto;
  }
  .bot3 .bottom {
    padding: 0;
  }
  .bot1 .divcon .rig {
    margin-left: 2%;
  }
  .banner .baner_back {
    width: 86.5%;
  }
  .banner {
    height: 700px;
  }
  .banner .container .rig {
    width: 50%;
    margin-top: 60px;
  }

  .banner .container .let .p1 {
    font-size: 58px;
  }
}

@media (max-width: 1259px) {
  .bot4 .container .let {
    width: 46%;
  }
  .bot4 .container .let .iti2 {
    font-size: 30px;
  }

  .bot1 .divcon .let {
    width: 98%;
  }

  .bot1 .divcon .rig {
    padding-left: 25px;
  }

  .bot1 .divcon .rig .bottom {
    width: 100%;
  }
  .bot1 .divcon .rig .top .p2 {
    width: 93%;
  }
  .banner .container .let .p1 {
    font-size: 48px;
  }
  .banner {
    height: 583px;
  }
}
@media (max-width: 991px) {
  .bot4 {
    padding-left: 12px;
    padding-right: 12px;
  }
  .baner_back {
    display: none;
  }
  .baner_back2 {
    display: block;
    position: absolute;
    bottom: 65px;
    width: 100%;
  }
  .banner .container .let .btncon .div2 {
    margin-left: 0;
    margin-top: 30px;
  }
  .banner .container .let .btncon {
    display: block;
  }
  .banner .container .let .btncon .div1 {
    width: 260px;
  }
  .banner .container .let .p2 {
    font-size: 20px;
  }
  .banner .container .let .p2 br {
    display: none;
  }
  .banner .container {
    padding: 0 24px;
  }
  .banner .container .let .p1 {
    font-size: 52px;
    line-height: 55px;
  }

  .banner .container .let {
    margin-top: 88px;
  }

  .banner .container .let,
  .banner .container .rig {
    width: 100%;
  }
  .banner .container {
    position: static;
    transform: translate(0);
    display: block;
    height: 100%;
  }
  .banner {
    height: auto;
  }
  .bot4 .container .let .btncon .div1 {
    width: 280px;
  }
  .bot4 .container .let .btncon .div2 {
    margin-left: 0;
    margin-top: 20px;
  }

  .bot4 .container .let .btncon {
    display: block;
  }

  .bot4 .container .rig {
    width: 100%;
  }
  .bot4 .container .let .iti2 {
    font-size: 35px;
  }

  .bot4 {
    padding-top: 70px;
    padding-bottom: 120px;
  }

  .bot4 .container {
    display: block;
  }
  .bot4 .container .let {
    width: auto;
    margin-left: 0;
    padding: 0 24px;
  }
  .bot2 .container .let {
    margin-top: 52px;
  }
  .bot2 {
    margin-top: 70px;
    padding: 0 13px;
  }

  .bot2 .container .rig .list .t1 {
    display: flex;
  }
  .bot2 .container .rig .list .t1 span {
    display: block;
  }
  .bot1 .divcon .let {
    width: 100%;
  }
  .bot1 .divcon .let .bottom .div {
    margin-left: 0 !important;
    width: 100%;
    margin-bottom: 16px;
  }
  .bot1 .divcon .let .bottom {
    display: block;
  }
  .bot1 {
    margin-top: 65px;
  }

  .bot1 .divcon {
    margin-top: 36px;
  }

  .bot1 .divcon .rig {
    padding: 0;
  }
  .bot1 .divcon .top .rig {
    width: 100%;
    margin-top: 36px;
  }
  .container {
    padding: 0 12px;
  }
  .bot1 .divcon .let .top {
    padding: 0 12px;
    padding-top: 32px;
    display: block;
  }
  .bot1 .divcon {
    display: block;
  }
  .bot1 .p1 {
    font-size: 34px;
  }
  .bot1 .divcon .rig .bottom {
    margin-top: 15px;
  }
  .bot1 .divcon .rig {
    width: auto;
    margin: 0;
    padding-top: 32px;
    padding-left: 12px;
  }
  .bot2 .container .rig .tt1 br,
  .bot2 .container .rig .t2 br {
    display: none;
  }
  .bot2 .container .rig .list .t1 img {
    width: 32px;
    height: 32px;
  }
  .bot2 .container .rig .list .t1 {
    font-size: 18px;
  }
  .bot2 .container .rig {
    margin-left: 0;
  }
  .bot2 .container {
    flex-direction: column-reverse;
  }
  .banner .container .rig {
    margin-top: 0;
    padding: 20px 0;
  }
  .bot3 .tit {
    font-size: 34px;
  }
  .bot3 {
    padding-top: 58px;
  }
}
@media (max-width: 500px) {
  .banner {
    height: auto;
  }
  .imgg1 {
    display: none;
  }
  .imgg2 {
    display: block;
  }
  .banner .container .rig img {
    width: auto;
  }
}
</style>

<style>
.botcc {
  display: none !important;
}
.swiper-pagination-bullet {
  width: 16px !important;
  height: 16px !important;
  background: none !important;
  border: 1px solid #1311ae !important;
}
.swiper-pagination-bullet-active {
  background: #3808d6 !important;
}
</style>