<template>
  <div class="header-app pc" :class="showAppHeader ? 'open-menu' : ''">
    <div class="navbar-app fd">
      <div
        class="header_left"
        @click="handleActive(0)"
        :class="showAppHeader ? 'no' : ''"
      >
        <img src="@/assets/images/img/img1.png" class="img" />
        <img src="@/assets/images/img/img4.png" class="img2" />
      </div>

      <div class="navbar-togger" @click="showAppHeaderMethod()">
        <img
          src="@/assets/images/img/Hamburgerw.png"
          alt=""
          srcset=""
          v-if="imgtype"
        />
        <img
          src="@/assets/images/img/Hamburger2.png"
          alt=""
          srcset=""
          v-if="!imgtype"
        />
      </div>
    </div>
    <div class="nav-menu">
      <ul class="nav-list">
        <li class="P_parent open">
          <div class="cate-item">
            <a
              href="javascript::"
              class="category-a"
              :class="headerActice == 0 ? 'active' : ''"
              @click="handleActive(0)"
              >Home</a
            >
          </div>
        </li>
        <li class="P_parent">
          <div class="cate-item">
            <a
              href="javascript::"
              class="category-a"
              :class="headerActice == 1 ? 'active' : ''"
              @click="handleActive(1)"
              >About</a
            >
          </div>
        </li>
        <li class="P_parent">
          <div class="cate-item">
            <a
              href="javascript::"
              class="category-a"
              :class="headerActice == 2 ? 'active' : ''"
              @click="handleActive(2)"
            >
              Support
            </a>
          </div>
        </li>
        <li class="P_parent">
          <div class="cate-item">
            <a
              href="javascript::"
              class="category-a"
              :class="headerActice == 3 ? 'active' : ''"
              @click="handleActive(3)"
            >
              Contacts
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
export default {
  name: "trueappheader",
  props: {
    headerActice: {
      type: Number,
      defluat() {
        return 0;
      },
    },
  },
  data() {
    return {
      imgtype: true,
      isShow: false,
      showAppHeader: false,
      i: 0,
    };
  },
  methods: {
    handleActive(e) {
      console.log(12312);
      this.showAppHeader = false;
      console.log(e);
      this.$emit("handleActive", e);
      this.i = 0;
      this.imgtype = true;
    },
    showAppHeaderMethod() {
      this.i++;
      console.log(this.i);
      if (this.i % 2 == 0) {
        this.showAppHeader = false;
        this.imgtype = true;
      } else {
        this.showAppHeader = true;
        this.imgtype = false;
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.no {
  display: none !important;
}
.header_left {
  display: flex;
  align-items: center;
  .img {
    width: 30px;
    height: 30px;
    margin-right: 13px;
  }
  .img2 {
    width: 106px;
    height: 20px;
  }
}
.header-app {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 1000;
  -webkit-transition: background 0.44s 0.2s cubic-bezier(0.52, 0.16, 0.24, 1),
    height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);
  -o-transition: background 0.44s 0.2s cubic-bezier(0.52, 0.16, 0.24, 1),
    height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);
  transition: background 0.44s 0.2s cubic-bezier(0.52, 0.16, 0.24, 1),
    height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);
}

.header-app.open-menu {
  height: 100%;
  background: #fff;
  -webkit-transition: background 0.36s cubic-bezier(0.32, 0.08, 0.24, 1),
    height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);
  -o-transition: background 0.36s cubic-bezier(0.32, 0.08, 0.24, 1),
    height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);
  transition: background 0.36s cubic-bezier(0.32, 0.08, 0.24, 1),
    height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);
}

.navbar-app {
  position: relative;
  height: 70px;
  display: flex;
  background: #101323;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0 25px;
}

.header-app .logo {
  float: left;
  height: 60px;
  vertical-align: middle;
  padding: 0px 0px;
}

.header-app .logo img {
  height: 100%;
}

.navbar-togger {
  position: absolute;
  right: 0;
  background: transparent;
  border: none;
  padding: 22px 15px;
  display: inline-block;
  visibility: visible;
  transition: transform 0.44s 0.2s cubic-bezier(0.04, 0.04, 0.12, 0.96),
    opacity 0.28s 0.36s cubic-bezier(0.52, 0.16, 0.24, 1),
    -webkit-transform 0.44s 0.2s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}

.navbar-togger .icon_bar {
  width: 24px;
  height: 2px;
  background-color: #000;
  opacity: 1;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.navbar-togger .icon_bar:nth-of-type(2) {
  margin: 5px 0;
}

.open-menu .navbar-togger .icon_bar:nth-of-type(2) {
  opacity: 0;
}

.open-menu .navbar-togger .icon_bar:first-child {
  -webkit-transform: translate(0, 7px) rotate(45deg);
  -ms-transform: translate(0, 7px) rotate(45deg);
  transform: translate(0, 7px) rotate(45deg);
}

.open-menu .navbar-togger .icon_bar:last-child {
  -webkit-transform: translate(0, -7px) rotate(-45deg);
  -ms-transform: translate(0, -7px) rotate(-45deg);
  transform: translate(0, -7px) rotate(-45deg);
}

.nav-menu {
  position: absolute;
  top: 60px;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 620;
  padding: 20px 15px;
  overflow-x: hidden;
  overflow-y: auto;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 1s;
  -o-transition: visibility 0s linear 1s;
  transition: visibility 0s linear 1s;
}

.header-app.open-menu .nav-menu {
  visibility: visible;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.nav-list li {
  opacity: 0;
  pointer-events: none;
}
.P_parent .cate-item {
}

.nav-list li.open {
  border-bottom: none;
}

.header-app.open-menu .nav-list li {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.nav-list li:nth-child(1) {
  -webkit-transform: translateY(-44px);
  -ms-transform: translateY(-44px);
  transform: translateY(-44px);
  transition: opacity 0.3345s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.15s,
    transform 0.4669s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.108s,
    -webkit-transform 0.4669s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.108s;
}

.header-app.open-menu .nav-list li:nth-child(1) {
  transition: opacity 0.3091s cubic-bezier(0.32, 0.08, 0.24, 1) 0.03s,
    transform 0.3455s cubic-bezier(0.32, 0.08, 0.24, 1) 0.02s,
    -webkit-transform 0.3455s cubic-bezier(0.32, 0.08, 0.24, 1) 0.02s;
}

.nav-list li:nth-child(2) {
  -webkit-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  transform: translateY(-40px);
  transition: opacity 0.29294s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.13s,
    transform 0.45043s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.095s,
    -webkit-transform 0.45043s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.095s;
}

.header-app.open-menu .nav-list li:nth-child(2) {
  transition: opacity 0.32244s cubic-bezier(0.32, 0.08, 0.24, 1) 0.05s,
    transform 0.35825s cubic-bezier(0.32, 0.08, 0.24, 1) 0.04s,
    -webkit-transform 0.35825s cubic-bezier(0.32, 0.08, 0.24, 1) 0.04s;
}

.nav-list li:nth-child(3) {
  -webkit-transform: translateY(-36px);
  -ms-transform: translateY(-36px);
  transform: translateY(-36px);
  transition: opacity 0.26098s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.11s,
    transform 0.43756s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.082s,
    -webkit-transform 0.43756s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.082s;
}

.header-app.open-menu .nav-list li:nth-child(3) {
  transition: opacity 0.33467s cubic-bezier(0.32, 0.08, 0.24, 1) 0.07s,
    transform 0.37539s cubic-bezier(0.32, 0.08, 0.24, 1) 0.06s,
    -webkit-transform 0.37539s cubic-bezier(0.32, 0.08, 0.24, 1) 0.06s;
}

.nav-list li:nth-child(4) {
  -webkit-transform: translateY(-32px);
  -ms-transform: translateY(-32px);
  transform: translateY(-32px);
  transition: opacity 0.2386s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.09s,
    transform 0.42827s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.069s,
    -webkit-transform 0.42827s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.069s;
}

.header-app.open-menu .nav-list li:nth-child(4) {
  transition: opacity 0.34577s cubic-bezier(0.32, 0.08, 0.24, 1) 0.09s,
    transform 0.39692s cubic-bezier(0.32, 0.08, 0.24, 1) 0.08s,
    -webkit-transform 0.39692s cubic-bezier(0.32, 0.08, 0.24, 1) 0.08s;
}

.nav-list li:nth-child(5) {
  -webkit-transform: translateY(-28px);
  -ms-transform: translateY(-28px);
  transform: translateY(-28px);
  transition: opacity 0.22581s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.07s,
    transform 0.42259s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.056s,
    -webkit-transform 0.42259s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.056s;
}

.header-app.open-menu .nav-list li:nth-child(5) {
  transition: opacity 0.35577s cubic-bezier(0.32, 0.08, 0.24, 1) 0.11s,
    transform 0.42286s cubic-bezier(0.32, 0.08, 0.24, 1) 0.1s,
    -webkit-transform 0.42286s cubic-bezier(0.32, 0.08, 0.24, 1) 0.1s;
}

.nav-list li:nth-child(6) {
  -webkit-transform: translateY(-24px);
  -ms-transform: translateY(-24px);
  transform: translateY(-24px);
  transition: opacity 0.20343s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.05s,
    transform 0.41691s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.043s,
    -webkit-transform 0.41691s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.043s;
}

.header-app.open-menu .nav-list li:nth-child(6) {
  transition: opacity 0.36577s cubic-bezier(0.32, 0.08, 0.24, 1) 0.13s,
    transform 0.41718s cubic-bezier(0.32, 0.08, 0.24, 1) 0.12s,
    -webkit-transform 0.41718s cubic-bezier(0.32, 0.08, 0.24, 1) 0.12s;
}

.nav-list li:nth-child(7) {
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  transition: opacity 0.18105s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.03s,
    transform 0.41123s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.03s,
    -webkit-transform 0.41123s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.03s;
}

.header-app.open-menu .nav-list li:nth-child(7) {
  transition: opacity 0.37577s cubic-bezier(0.32, 0.08, 0.24, 1) 0.15s,
    transform 0.4115s cubic-bezier(0.32, 0.08, 0.24, 1) 0.14s,
    -webkit-transform 0.4115s cubic-bezier(0.32, 0.08, 0.24, 1) 0.14s;
}

.nav-list li:nth-child(8) {
  -webkit-transform: translateY(-16px);
  -ms-transform: translateY(-16px);
  transform: translateY(-16px);
  transition: opacity 0.15867s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.01s,
    transform 0.40555s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.17s,
    -webkit-transform 0.40555s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.17s;
}

.header-app.open-menu .nav-list li:nth-child(8) {
  transition: opacity 0.38577s cubic-bezier(0.32, 0.08, 0.24, 1) 0.17s,
    transform 0.40582s cubic-bezier(0.32, 0.08, 0.24, 1) 0.16s,
    -webkit-transform 0.42286s cubic-bezier(0.32, 0.08, 0.24, 1) 0.16s;
}

.cate-item {
  position: relative;
}

.cate-item a {
  font-family: "regular";
  font-size: 16px;
  line-height: 48px;
  margin-right: 60px;
  display: block;
  color: #333;
}

.category-cont a:last-child {
  border: none;
}

.active {
  color: #5b53ff !important;
  border-bottom: 2px solid #5b53ff;
}

.category-tig {
  font-size: 14px;
  color: #888;
  text-align: right;
  line-height: 48px;
  width: 50px;
  height: 48px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.category-tig::before,
.category-tig::after {
  content: "";
  background: #888;
  position: absolute;
  top: 50%;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.category-tig::before {
  width: 16px;
  height: 2px;
  right: 0;
  margin-top: -1px;
}

.category-tig::after {
  width: 2px;
  height: 16px;
  margin-top: -8px;
  right: 7px;
}

.nav-list li.open .category-tig::after {
  opacity: 0;
}

.nav-list li.open .category-tig::before {
  background: #fe7392;
}

.category-cont {
  display: none;
}

.nav-list li.open .category-cont {
  display: block;
  border-bottom: 1px solid rgba(133, 131, 131, 0.5) !important;
  border-top: 1px solid rgba(133, 131, 131, 0.5) !important;
}

.category-pad {
  padding: 0 15px;
}

.category-cont a {
  font-family: "regular";
  font-size: 14px;
  line-height: 40px;
  border-bottom: 1px solid rgba(133, 131, 131, 0.5) !important;
  display: block;
  color: #333;
}
.category-cont a:last-child {
  border-bottom: none !important;
}
.category-pad a.on {
  color: #e51c24;
}

.lan-box-app {
  padding: 20px 0;
  text-align: center;
}

.lan-box-app a {
  font-size: 14px;
  color: #333;
  display: inline-block;
  padding: 0 10px;
  position: relative;
}

.lan-box-app a:after {
  content: "";
  width: 1px;
  height: 10px;
  background: #333;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -5px;
}

.lan-box-app a:hover,
.lan-box-app a.on {
  color: #fe7392;
}

.lan-box-app a:last-child:after {
  display: none;
}
.cate-item a {
  font-size: 24px;
  width: 100%;
}
.P_parent {
  text-align: center;
  margin-top: 56px;
}
.P_parent:first-child {
  margin-top: 0;
}
.nav-menu {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cate-item a {
  font-size: 24px;
  width: auto;
  margin: 0 auto;
}
.navbar-togger img {
  width: 48px;
  height: 48px;
}
@media (max-width: 500px) {
  .header-app .logo {
    height: 45px;
    padding: 8px 10px;
  }
}
</style>