<template>
  <div class="">
    <!-- <app-footer /> -->
    <trueappheader
      :headerActice="headerActice"
      @handleActive="handleActive"
      v-if="header_show"
      
    ></trueappheader>
      <trueappheader2
      :headerActice="headerActice"
      @handleActive="handleActive"
      v-if="header_show==false"

    ></trueappheader2>
       <app-header2
      v-if="header_show==false"
           :headerActice="headerActice"
      @handleActive="handleActive"

    ></app-header2>
        <div class="container">
    <app-header
      v-if="header_show"
      :headerActice="headerActice"
      @handleActive="handleActive"
    ></app-header>
    </div>
      
      <router-view v-on:header="header" v-on:footer="footer">
             <andDown
        v-if="headerActice == 4" 
       />
        <home 
        v-if="headerActice == 0" 
         @handleActive="handleActive"

       />

 <mapp  v-else-if="headerActice == 1" />

        <real-time-info 
        v-else-if="headerActice == 2"
        @goToMoreNew="goToMoreNew"
        @goToDetail="goToDetail"
        @goToCooperate="goToCooperate"
         />
        <join-in v-else-if="headerActice == 3" />
      </router-view>
    <app-footer
      v-if="footer_show"
      :headerActice="headerActice"
      @handleActive="handleActive"
    ></app-footer>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Home from "./components/Home.vue";
import RealTimeInfo from "./components/RealTimeInfo.vue";
import JoinIn from "./components/JoinIn.vue";
import trueappheader from "./components/appheader.vue";
import trueappheader2 from "./components/appheader2.vue";
import andDown from "./components/andDown.vue";

import appheaderMain from "./components/appheaderMain.vue";

import mapp from "./components/mapp.vue";

export default {
  name: "App",
  components: {
    "app-header": Header,
    "app-footer": Footer,
    "app-header2": appheaderMain,
    Home,
    RealTimeInfo,
    JoinIn,
    trueappheader,
    trueappheader2,
    mapp,
    andDown
  },
  
  data() {
    return {
      header_show:false ,
      footer_show: true,
      headerActice: localStorage.getItem('headerActice') || 0,
      isFixed:false,
      newDetail: ''
    };
  },
  created() {
   　window.addEventListener('scroll',this.handleScroll) 



    // localStorage.setItem('headerActice', 0);
    console.log(this.headerActice);
    console.log(this.header_show,4444444)
   console.log( localStorage.getItem('zheng') ,787878)
   localStorage.setItem('headerActice',0)
   this.headerActice=0
 if(localStorage.getItem('headerActice')>0){
   this.header_show=true
  localStorage.setItem('zheng',true)
 }else{
     this.header_show=false
  localStorage.setItem('zheng',false)

 }
   console.log( this.header_show,787878)
      

  },
  watch: {
    headerActice() {
      // 切换页面时滚动条自动滚动到顶部
      window.scrollTo(0,0);
    }
  },
  methods: {

    //是否显示头部
    // header: function (bool) {
    //   this.header_show = bool;
    // },
    //是否显示底部
    footer: function (bool) {
      this.footer_show = bool;
    },
    // 选择了哪个
    handleActive(e) {
      this.headerActice = e;
      if(this.headerActice==0){
     this.header_show=false
         localStorage.setItem('zheng',this.header_show);
      }else if(this.headerActice>0){
   this.header_show=true
         localStorage.setItem('zheng',this.header_show);
      }
      localStorage.setItem('headerActice', e);

    },


   
  },
};
</script>



<style lang="scss">
@media (min-width: 768px) {
  .container, .wrap, .index-container, .foot-container, .drop-container {
    padding-right: 24px;
    padding-left: 24px;
  }
  .wrap, .container, .foot-container {
    width: 750px;
     margin: 0 auto;

  }
}

@media (min-width: 992px) {
  .index-container, .wrap, .container, .foot-container {
    width: 970px;
     margin: 0 auto;

  }
}

@media (min-width: 1260px) {
  .index-container, .foot-container {
    width: 1200px;
  }
   .wrap, .container{
    width: 1140px;
     margin: 0 auto;

  }
}

@media (min-width: 1460px) {
  .container,  .foot-container {
    width: 1400px;
     margin: 0 auto;

  }
.index-container{
    width: 1450px;
  }

}

@media (min-width: 1680px) {
  /* 底部*/
  .foot-container {
    width: 1650px;
  }
  /*  首页内容区 */
  .container {
       width: 1650px;
     margin: 0 auto;
  }
  /* 头部 */
  .index-container {
    width: 1840px;
  }
}


.container{
  // overflow: visible !important;
}
.container-con{

}
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
}
#app {
  font-family: Source Han Sans CN;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  color: #1C1C1C;
}
</style>

<style>
/* html,body{
    cursor: url('./assets/images/img/Pointer.png'),auto;
} */

 div:hover {
    cursor: url('./assets/images/img/Pointer.png'),pointer !important;
        }
         button:hover {
    cursor: url('./assets/images/img/Pointer.png'),pointer !important;
        }
</style>